import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './lan/en'
import zh from './lan/zh'

Vue.use(VueI18n)

export const messages = {
  en,
  zh,
}


const i18n = new VueI18n({
  locale: localStorage.getItem("locale") || "en", // 设置地区
  fallbackLocale: 'en', // 如果当前语言环境的语言资源不存在，则回退到默认
  messages, // 设置地区信息
})

export default i18n
