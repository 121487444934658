import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/IndexView'),
  },
  {
    path: '/download',
    component: () =>
      import(/* webpackChunkName: "download" */ '@/views/DownloadView'),
    children: [
      {
        path: '/',
        name: 'download',
        component: () =>
          import(/* webpackChunkName: "windows" */ '@/views/DownloadView/windows'),
      },
      {
        path: 'windows',
        name: 'downloadWindows',
        component: () =>
          import(/* webpackChunkName: "windows" */ '@/views/DownloadView/windows'),
      },
      {
        path: 'mac',
        name: 'downloadMac',
        component: () =>
          import(/* webpackChunkName: "mac" */ '@/views/DownloadView/mac'),
      },
      {
        path: 'ios',
        name: 'downloadIos',
        component: () =>
          import(/* webpackChunkName: "ios" */ '@/views/DownloadView/ios'),
      },
      {
        path: 'linux',
        name: 'downloadLinux',
        component: () =>
          import(/* webpackChunkName: "linux" */ '@/views/DownloadView/linux'),
      },
      {
        path: 'android',
        name: 'downloadAndroid',
        component: () =>
          import(/* webpackChunkName: "android" */ '@/views/DownloadView/android'),
      },
      {
        path: 'android-tv',
        name: 'downloadAndroidTV',
        component: () =>
          import(/* webpackChunkName: "androidTV" */ '@/views/DownloadView/androidTV'),
      },
    ],
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "blog" */ '@/views/BlogView'),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '@/views/FAQView'),
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/LoginView'),
  },
  {
    path: '/affiliate-sign-in',
    name: 'AffiliateSignIn',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/LoginView'),
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: () =>
      import(/* webpackChunkName: "purchase" */ '@/views/DownloadView'), // TODO:
  },
  {
    path: '/purchase-custom',
    name: 'purchaseCustom',
    component: () =>
      import(/* webpackChunkName: "purchaseCustom" */ '@/views/DownloadView'), // TODO:
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () =>
      import(/* webpackChunkName: "PrivacyPolicy" */ '@/views/PrivacyPolicy'),
  },
  {
    path: '/terms-of-service',
    name: 'termsOfService',
    component: () =>
      import(/* webpackChunkName: "TermsOfService" */ '@/views/TermsOfService'),
  },
  {
    path: '/server-details',
    name: 'serverDetails',
    component: () =>
      import(/* webpackChunkName: "serverDetails" */ '@/views/ServerDetails'),
  },
  {
    path: '/ip-lookup',
    name: 'ipLookup',
    component: () =>
      import(/* webpackChunkName: "ipLookup" */ '@/views/IpLookup'),
  },
  {
    path: '/affiliate-program',
    name: 'affiliateProgram',
    component: () =>
      import(/* webpackChunkName: "affiliateProgram" */ '@/views/AffiliateProgram'),
  },
  { // TODO
    path: '/region-guide-ios',
    name: 'regionGuideIos',
    component: () =>
      import(/* webpackChunkName: "regionGuideIos" */ '@/views/AffiliateProgram'),
  },
  { // TODO
    path: '/password-reset',
    name: 'passwordReset',
    component: () =>
      import(/* webpackChunkName: "passwordReset" */ '@/views/AffiliateProgram'),
  },
  {
    path: '*',
    name: 'error',
    // redirect: '/error',
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/ErrorView'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
