export default {
  'xxx':'xxx',
  header: {
    ip: '您的 IP 地址',
    Status: '您当前的状态',
    Unprotected: '未保护',
    tips: '要隐藏您的 IP 地址，您需要下载 PandaVPN 并连接至一台服务器。',
    Homepage: '首页',
    Download: '下载',
    Blog: '博客',
    Help: '帮助中心',
    Sign: '登录',
  },
  footer: {
    About:'关于我们',
    a1:'隐私政策',
    a2:'服务条款',
    a3:'服务器详情',
    a4:'IP 查询',
    Download:'下载',
    Help:'帮助中心',
    FAQ:'常见问题',
    Cooperation:'合作',
    Become:'我要加盟',
    Payment:'我要加盟',
  },
  home:{
    '7-day': '7 天退款保障',
    'Anonymous-long':'加密浏览、隐藏痕迹 高速访问互联网 安全易用的 VPN',
    'Anonymous':'加密浏览、隐藏痕迹',
    'Fast':'高速访问互联网',
    'Easy':'安全易用的 VPN',
    'get':'获取 PandaVPN',
    'Download':'下载',
    'Bypass':'突破地域限制',
    'Unlimited':'自由获取世界各地的视频、音乐、社交媒体等',
    services: {
      title: 'PandaVPN 能让您享受到哪些服务',
      t1:'解锁流媒体服务，各国视频平台您都可以流畅观看',
      p1:'解除地域限制，在世界任何地区都能流畅观看 NETFLIX、HULU、HBO、AbemaTV、Disney 等流媒体内容，让您能自由地访问自己喜欢的内容。',
      t2:'虚拟 IP 匿名访问互联网，隐匿您的一切互联网行踪',
      p2:'使用 PandaVPN 时，仿佛是您在互联网上披上了隐形斗篷。您在此加密连接后的所有操作都会是 100% 匿名且不可追溯的，因此您的私人数据将受到有力的保护。',
      t3:'加密您的所有访问数据，确保网络连接安全',
      p3:'在公共 WIFI 下，黑客可以窃取您的密码和数据，威胁您的账户安全和财产安全，网络服务提供商可以查询到您的所有浏览记录。使用 PandaVPN 将对您的所有访问数据进行加密，让您的数据在任何网络下都绝对安全。',
    },
    devices:{
      t:'您的所有设备都将受到保护',
      p:'PandaVPN 对市面上所有主流的设备都做了支持，以确保可以在多平台设备上为您提供隐私数据保护。您可以时刻享受 Windows、Mac、iOS、Android、Linux、Android TV 精美无障碍的客户端，为了给您完美体验，连接 VPN 只需一个开关即可。使用 PandaVPN，多台设备访问安全且无限制的网络，只需片刻之间。',
      t1:'超快专线',
      p1:'PandaVPN 所有服务器网络都执行了专利加速技术，更有超快 IPLC 专线， 让您可以流畅快速地浏览与传输。没有限流，没有缓冲，没有麻烦。',
      t2:'无日志政策',
      p2:'PandaVPN 始终严格执行无日志记录政策，确保所有的应用程序和平台之间的数据完全匿名，更大程度上保护了您的隐私。',
      t3:'最高安全 ECC 加密技术',
      p3:'您的任何连接访问，都将受到保护。通过 PandaVPN 最高安全 ECC 加密技术，可以确保您的任何数据都不会被窥探到。',
    },
    connection: {
      t:'随时随地，享受快速稳定的连接',
      p:'网络缓慢难以忍受？不用担心，PandaVPN 目前在全球支持 80 个国家，170 个 VPN 服务器位置，拥有超过 3000 台服务器，并且还在持续地拓展服务。确保无论您从何处进行连接，都始终能在附近能找到高带宽服务器且低延迟的 VPN 连接，享受最佳的性能。',
      t1:'解除地域限制',
      p1:'PandaVPN 更改了您的虚拟位置，访问各国网站、跨服玩游戏、观看各国流媒体全都不再有任何障碍。',
      t2:'不限带宽，无限流量',
      p2:'PandaVPN 加速您的网络访问，线路带宽最高可达 10 Gbps，无限流量任您使用。',
      t3:'提供全球技术支持',
      p3:'您可以直接与我们的技术人员联系以寻求帮助，我们将帮助您解决所有问题。',
      more:'更多国家/地区'
    },
    Comments:{
      t:'PandaVPN 用户的评价'
    },
    Plan: {
      t:'特惠套餐任意选择',
      p1:'所有套餐均支持',
      p2:'台设备',
      btn:'多设备定制',
      next:'多设备定制',
      'Months':'个月',
      'Free1':'赠送',
      'Free2':'个月',
      'perMonth':'每月',
      'OFF':'OFF',
      'guarantee':'天退款保障',
      'get':'获取 PandaVPN',
    }
  }
}