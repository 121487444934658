<template>
  <div class="cookie-consent">
    <div class="cookie-message">
      为改善用户体验，此网站使用 Cookie。要了解更多关于 Cookie 政策的信息或拒绝使用 Cookie，请查看我们的
      <a href="">隐私政策</a>
      <span>。</span>
    </div>
    <div class="cookie-agree">同意</div>
  </div>
</template>
<script>
export default {
  name: 'cookie-consent',
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>
<style lang="less" scoped>

.cookie-consent {
    position: fixed;
    bottom: 15px;
    background-color: #fff;
    max-width: 1024px;
    width: 100%;
    z-index: 2147483004;
    left: calc(50% - 512px);
    padding: 15px 30px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,.3);
    color: #7f7f7f;
    display: flex;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    display: none
}

.cookie-consent.cookie-consent-show {
    display: flex
}

.cookie-consent.cookie-hide {
    display: none!important
}

@media screen and (max-width: 1024px) {
    .cookie-consent {
        left:0;
        bottom: 0;
        font-size: 12px
    }
}

.cookie-consent .cookie-message {
    flex: 1;
    padding-right: 15px
}

.cookie-consent a {
    color: #47b96d
}

.cookie-consent .cookie-agree {
    padding: 0 15px;
    background-color: #47b96d;
    border-radius: 32px;
    text-align: center;
    color: #fff;
    height: 32px;
    line-height: 32px;
    cursor: pointer
}
</style>
