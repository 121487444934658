export default {
  header: {
    ip: 'Your IP',
    Status: 'Your Status',
    Unprotected: 'Unprotected',
    tips: 'To hide your IP address, you need to get the PandaVPN and connect to one of the servers.',
    Homepage: 'Homepage',
    Download: 'Download',
    Blog: 'Blog',
    Help: 'Help Center',
    Sign: 'Sign In',
  },
  footer: {
    About:'About Us',
    a1:'Privacy Policy',
    a2:'Terms of Service',
    a3:'Server Details',
    a4:'IP Address Lookup',
    Download:'Download',
    Help:'Help Center',
    FAQ:'FAQ',
    Cooperation:'Cooperation',
    Become:'Become an Affiliate',
    Payment:'Payment Method',
  },
  home:{
    '7-day': '7-day money-back guarantee',
    'Anonymous-long':'Anonymous Browsing Fast & Secure Connection Easy-to-Use VPN',
    'Anonymous':'Anonymous Browsing',
    'Fast':'Fast & Secure Connection',
    'Easy':'Easy-to-Use VPN',
    'get':'Get PandaVPN',
    'Download':'Download',
    'Bypass':'Bypass geo-blocking',
    'Unlimited':'nlimited access to video, music, social media, and more from anywhere in the world',
    services: {
      title: 'What services can you enjoy with PandaVPN',
      t1:'Unblock streaming media, watch favored content smoothly anywhere',
      p1:'By bypassing geo-restrictions, you can binge-watch NETFLIX, HULU, HBO (Max), AbemaTV, Disney+ and other national streaming platforms fluently from anywhere and access your favourite content freely.',
      t2:'Hide IP address, stay anonymous online completely with a virtual IP',
      p2:'PandaVPN gives you an invisibility cloak on the Internet. All your online activity is 100% anonymous and untraceable with encrypted connections. Your personal data will be securely protected.',
      t3:'Encrypt all your data, maintain a secure network connection always',
      p3:'Under public WIFI, hackers can steal your password and data, threaten your security and property security. Network service providers can query your browsing history. PandaVPN encrypts all your data and keeps it absolutely safe under any network.',
    },
    devices:{
      t:'Protect all your devices',
      p:'PandaVPN supports all mainstream devices on the market to ensure that your private data is protected on multi-platform devices. You can enjoy exquisite and user-friendly clients for Windows, Mac, iOS, Android, Linux, and Android TV. With PandaVPN, one-click connect and server selection, you can access an unlimited network.',
      t1:'Ultra-fast servers',
      p1:'All of the servers have implemented patented acceleration technology. What’s more, ultra-fast IPLC servers are provided to you. No traffic limit, no buffering, no trouble.',
      t2:'Truly no logs policy',
      p2:'PandaVPN strictly implements no logs policy to ensure that all data between applications and platforms are completely anonymous and protect your online privacy.',
      t3:'The highest level of ECC encryption',
      p3:'All of your Internet access can be protected through our highest level of secure ECC encryption technology. Therefore, no one can snoop your data.',
    },
    connection: {
      t:'Enjoy fast and stable connection anywhere, anytime',
      p:`The internet is slow and unbearable? Don't worry. PandaVPN currently supports over 3000 servers in 170 VPN server locations in 80 countries, and the number is still growing. This ensures that no matter where you are, you can always find a high-bandwidth and low-latency server nearby and enjoy the best network performance.`,
      t1:'Unblock GEO restrictions',
      p1:'PandaVPN will change your virtual location, and help you unblock the restrictions to visit websites, play games and watch streaming videos.',
      t2:'No bandwidth nor traffic limit',
      p2:'PandaVPN will speed up your network. The server bandwidth can reach 10Gbps, without traffic limitation.',
      t3:'Global technique support',
      p3:'You can talk directly to our tech support or even developers for help. Our technical staff will help you solve all of your problems.',
      more:'See VPN Locations'
    },
    Comments:{
      t:'User Comments'
    },
    Plan: {
      t:'Select a Plan',
      p1:'Secure up to',
      p2:'devices for all plans',
      btn:'Customize Multiple Devices',
      next:'Next',
      'Months':'Months',
      'Free1':'+',
      'Free2':'Months Free',
      'perMonth':'per month',
      'OFF':'OFF',
      'guarantee':'-day money-back guarantee',
      'get':'Get PandaVPN',
    }
  }
}