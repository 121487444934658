<template>
  <div class="banner-popup-main" v-if="visibility">
    <div class="banner-popup">
      <div class="close"></div>
      <div class="banner-tips">
        <div class="">限时特惠</div>
        <div class="banner-time">
          涨价倒计时
          <span class="tiem"></span>
        </div>
      </div>
      <div class="banner-text">
        <div class="banner-name">12 个月</div>
        <div class="banner-award">赠送 12 个月</div>
        <div class="banner-price">
          $2.49
          <span class="banner-month">/月</span>
        </div>
        <div class="banner-discount">
          <div class="off-bg">83% OFF</div>
        </div>
        <a href="" class="btn">立即获取</a>
      </div>
      <div class="banner-popup-img">
        <img
          src="@/assets/images/summer2022/im_3x.png"
          alt=""
          class="banner-popup-img"
        />
      </div>
      <a href="" class="banner-mobile-btn"></a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BannerPopup',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      visibility: false,
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
}
</script>
<style lang="less" scoped>
.banner-popup-main {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  cursor: pointer;
  z-index: 99999999999;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.banner-popup {
  box-shadow: 0 0.75rem 0.75rem rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  letter-spacing: 1px;
  width: 400px;
  height: 337px;
  max-width: 100%;
  border-radius: 0.75rem;
  margin-left: 15px;
  margin-bottom: 15px;
  color: #fff;
  font-size: 18px;
  background-size: cover;
  line-height: 1.6;
  background-image: url('@/assets/images/bg.png');

  @media screen and (min-width: 820px) {
    & {
      transition: height 0s;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -12.5rem;
      margin-top: -10rem;
    }
  }
  @media screen and (max-width: 819px) {
    &.active {
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}

.banner-popup .close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 24px;
  height: 24px;
  background-image: url(@/assets/images//popup-close.svg);
  background-size: cover;
  cursor: pointer;
  z-index: 3;
}

.banner-popup .banner-tips {
  line-height: 1;
  padding: 30px 30px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  font-size: 18px;
  line-height: 30px;

  & .banner-time {
    font-size: 14px;
    line-height: 24px;
    display: block;
    width: 100%;
    color: hsla(0, 0%, 100%, 0.8);
  }
}

.panda-time,
.panda-time span {
  display: inline-flex;
}

.banner-text {
  padding: 15px 30px;

  .banner-price {
    font-size: 36px;

    .banner-month {
      font-size: 14px;
      position: relative;
      z-index: 2;
    }

  }
}
.banner-award,
.banner-name {
  font-size: 18px;
  line-height: 30px;
}

.off-bg {
  background-image: url(@/assets/images/OFF.png);
  background-size: cover;
  color: #fff !important;
  width: 130px;
  height: 24px;
  overflow: hidden;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
}

.banner-text .btn,
.banner-popup .banner-text .btn {
  background-color: #bd23ff;
  color: #fff;
}

.banner-popup .banner-popup-img {
  position: absolute;
  right: -0.9375rem;
  bottom: 0;
  width: 250px;
  z-index: 2;
}
</style>
