<template>
  <footer id="pandaFooter">
    <div>
      <div>
        <div>
          <h2>{{ $t('footer.About') }}</h2>
          <a href="/privacy-policy" class=""
            >{{ $t('footer.a1') }}</a
          >
          <a href="/terms-of-service" class=""
            >{{ $t('footer.a2') }}</a
          >
          <a href="/server-details" class=""
            >{{ $t('footer.a3') }}</a
          >
          <a href="/ip-lookup" class="">{{ $t('footer.a4') }}</a>
        </div>
        <div>
          <h2>{{ $t('footer.Download') }}</h2>
          <a href="/download/windows" class=""
            >Windows</a
          >
          <a href="/download/mac" class="">Mac</a>
          <a href="/download/ios" class="">iOS</a>
          <a href="/download/android" class=""
            >Android</a
          >
          <a href="/download/linux" class=""
            >Linux</a
          >
          <a href="/download/android-tv" class=""
            >Android TV</a
          >
        </div>
      </div>
      <div>
        <div>
          <h2>{{ $t('footer.Help') }}</h2>
          <p>panda7x24@gmail.com</p>
          <a href="/faq" class="">{{ $t('footer.FAQ') }}</a>
        </div>
        <div>
          <h2>{{ $t('footer.Cooperation') }}</h2>
          <a href="/affiliate-program" class=""
            >{{ $t('footer.Become') }}</a
          >
        </div>
        <div class="footer-payment">
          <h2>{{ $t('footer.Payment') }}</h2>
          <div>
            <!---->
            <picture placeholder="100"
              ><!---->
              <img
                src="@/assets/images/payment-methods-footer.png"
                srcset="@/assets/images/payment-methods-footer.png 392w"
                sizes="392px"
                width="392"
                height="30"
                alt="pay list"
                loading="lazy"
                class="footer-panda-pay-img"
            /></picture>
            <!---->
          </div>
        </div>
      </div>
    </div>
    <p class="copyright">© 2024 PandaVPN. All rights reserved.</p>
  </footer>
</template>
<script>
export default {
  name: 'LayoutHeader',
}
</script>
<style lang="less" scoped>
footer {
  background-color: #fafbfd;
  border-top: 1px solid #e0e0e0;
  font-size: 14px;
}
.footer {
  &-main {
    border-bottom: 1px solid #e0e0e0;
    padding: 45px 0 30px;
    max-width: 1140px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;

    &-row {
      width: 100%;
    }

    h2 {
      text-align: left;
      padding-top: 15px;
      font-size: 18px;
    }
    .a,
    .p {
      display: block;
      color: #7f7f7f;
      padding-top: 15px;
      text-align: left;
    }
  }
  &-item {
    width: 50%;
    min-width: 320px;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-self: baseline;
  }
}

.footer-panda-pay-img {
  width: 392px;
  max-width: 392px;
  max-height: 32px;
  height: auto;
  margin-top: 15px;
}

.copyright {
  text-align: center;
  padding: 1.875rem 0;
  color: #7f7f7f;
}
</style>

<style>
footer {
  background-color: #fafbfd;
  border-top: 1px solid #e0e0e0;
  font-size: 14px;
}

@media screen and (max-width: 320px) {
  footer {
    font-size: 12px;
  }
}

footer > div {
  border-bottom: 1px solid #e0e0e0;
  padding: 45px 0 30px;
  max-width: 1140px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

footer > div > div {
  width: 50%;
  min-width: 320px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-self: baseline;
}

footer > div > div > div {
  width: 50%;
  padding: 0 20px;
}

@media screen and (max-width: 768px) {
  footer > div > div > div.footer-payment {
    width: 100%;
  }

  footer > div > div > div .footer-panda-pay-img {
    width: 100% !important;
    max-width: 100% !important;
  }
}

footer > div > div > div h2 {
  padding-top: 15px;
  font-size: 18px;
}

footer > div > div > div a,
footer > div > div > div p {
  display: block;
  color: #7f7f7f;
  padding-top: 15px;
}

footer > div > div > div a.nuxt-link-exact-active,
footer > div > div > div a:hover,
footer > div > div > div p.nuxt-link-exact-active,
footer > div > div > div p:hover {
  color: #262626;
}

footer > div > div > div .footer-panda-pay-img {
  width: 160%;
  max-width: 160%;
  max-height: 32px;
  height: auto;
  margin-top: 15px;
}

footer .copyright {
  text-align: center;
  padding: 1.875rem 0;
  color: #7f7f7f;
}
</style>
