var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',[_c('div',{staticClass:"network"},[_c('img',{staticClass:"network-icon",attrs:{"src":require("@/assets/images/status-bar-icon-bb3ba5.png"),"width":"20","height":"17","alt":"logo","sizes":"20px","srcset":require("@/assets/images/status-bar-icon-bb3ba5.png") + " 20w"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('header.ip'))+"：1.162.177.83 · "+_vm._s(_vm.$t('header.Status'))+"： "),_c('span',{staticClass:"panda-tooltip unprotected"},[_vm._v(" "+_vm._s(_vm.$t('header.Unprotected'))),_c('span',{staticClass:"panda-header-tooltip tooltip-show"},[_vm._v(" "+_vm._s(_vm.$t('header.tips')))])])])]),_c('div',{staticClass:"header-content"},[_vm._m(0),_c('div',{staticClass:"header-menu"},[_vm._l((_vm.menuList),function(item){return _c('router-link',{key:item.key,staticClass:"a nuxt-link-active",class:{
          active:
            _vm.$route.name === item.key || _vm.$route.path.includes(`/${item.key}/`),
        },attrs:{"to":{ name: item.key }}},[_vm._v(_vm._s(item.label))])}),_c('el-popover',{attrs:{"placement":"bottom","trigger":"click","popper-class":"index-top header-dropdown-menu header-lang-menu"}},[_vm._l((_vm.langList),function(value,key){return _c('a',{key:key,class:_vm.$i18n.locale === key
                ? 'nuxt-link-exact-active nuxt-link-active'
                : '',on:{"click":function($event){return _vm.changeLan(key)}}},[_vm._v(" "+_vm._s(value)+" "),(_vm.$i18n.locale === key)?_c('i',{staticClass:"el-icon-check"}):_vm._e()])}),_c('span',{staticClass:"header-dropdown-link",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.lang)),_c('img',{staticClass:"menu-bottom",attrs:{"src":require("@/assets/images/menu-bottom-5efae5.svg"),"width":"18","height":"18","alt":"logo","sizes":"18px","srcset":require("@/assets/images/menu-bottom-5efae5.svg") + " 18w"}})])],2)],2),_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"logo nuxt-link-active",attrs:{"href":"/"}},[_c('img',{staticClass:"logo-img",attrs:{"src":require("@/assets/images/new-logo-1e29e5.svg"),"width":"198","height":"44","alt":"logo","sizes":"198px","srcset":require("@/assets/images/new-logo-1e29e5.svg") + " 198w"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-content-mobile"},[_c('div',[_vm._v(" 简体中文"),_c('img',{staticClass:"menu-bottom",attrs:{"src":require("@/assets/images/menu-bottom-5efae5.svg"),"width":"18","height":"18","alt":"logo","sizes":"18px","srcset":require("@/assets/images/menu-bottom-5efae5.svg") + " 18w"}})]),_c('div',{staticClass:"header-menu-mobile"})])
}]

export { render, staticRenderFns }