<template>
  <header>
    <div class="network">
      <img
        src="@/assets/images/status-bar-icon-bb3ba5.png"
        width="20"
        height="17"
        alt="logo"
        sizes="20px"
        srcset="@/assets/images/status-bar-icon-bb3ba5.png 20w"
        class="network-icon"
      />
      <span>
        {{ $t('header.ip') }}：1.162.177.83 ·
        {{ $t('header.Status') }}：
        <span class="panda-tooltip unprotected">
          {{ $t('header.Unprotected')
          }}<span class="panda-header-tooltip tooltip-show">
            {{ $t('header.tips') }}</span
          ></span
        ></span
      >
    </div>
    <div class="header-content">
      <a href="/" class="logo nuxt-link-active"
        ><img
          src="@/assets/images/new-logo-1e29e5.svg"
          width="198"
          height="44"
          alt="logo"
          sizes="198px"
          srcset="@/assets/images/new-logo-1e29e5.svg 198w"
          class="logo-img"
      /></a>
      <div class="header-menu">
        <router-link
          v-for="item in menuList"
          :key="item.key"
          :to="{ name: item.key }"
          class="a nuxt-link-active"
          :class="{
            active:
              $route.name === item.key || $route.path.includes(`/${item.key}/`),
          }"
          >{{ item.label }}</router-link
        >
        <!-- <a href="/login" class="">登录</a> -->
        <!-- <div class="header-dropdown-menu header-my-menu" style="right: 100px">
          <a href="/my-account" class="">我的账户</a>

          <a href="/change-password" class="">修改密码</a>
          <a><span class="color_error">退出登录</span></a>
        </div> -->

        <el-popover
          placement="bottom"
          trigger="click"
          popper-class="index-top header-dropdown-menu header-lang-menu"
        >
          <template>
            <a
              @click="changeLan(key)"
              :class="
                $i18n.locale === key
                  ? 'nuxt-link-exact-active nuxt-link-active'
                  : ''
              "
              v-for="(value, key) in langList"
              :key="key"
            >
              {{ value }}
              <i class="el-icon-check" v-if="$i18n.locale === key"></i>
            </a>
          </template>
          <!--  -->
          <span class="header-dropdown-link" slot="reference">
            {{ lang
            }}<img
              src="@/assets/images/menu-bottom-5efae5.svg"
              width="18"
              height="18"
              alt="logo"
              sizes="18px"
              srcset="@/assets/images/menu-bottom-5efae5.svg 18w"
              class="menu-bottom"
          /></span>
          <!--  -->
        </el-popover>
      </div>
      <div class="header-content-mobile">
        <div>
          简体中文<img
            src="@/assets/images/menu-bottom-5efae5.svg"
            width="18"
            height="18"
            alt="logo"
            sizes="18px"
            srcset="@/assets/images/menu-bottom-5efae5.svg 18w"
            class="menu-bottom"
          />
        </div>
        <div class="header-menu-mobile"></div>
      </div>
    </div>
  </header>
</template>
<script>
const langList = {
  en: 'English',
  zh: '简体中文',
}
export default {
  name: 'LayoutHeader',
  data() {
    return {
      langList,
    }
  },
  computed: {
    lang() {
      return langList[this.$i18n.locale]
    },
    menuList() {
      return [
        {
          label: this.$t('header.Homepage'),
          key: 'index',
        },
        {
          label: this.$t('header.Download'),
          key: 'download',
        },
        {
          label: this.$t('header.Blog'),
          key: 'blog',
        },
        {
          label: this.$t('header.Help'),
          key: 'faq',
        },
        {
          label: this.$t('header.Sign'),
          key: 'login',
        },
      ]
    }
  },
  methods: {
    changeLan(key) {
      console.log('key')
      localStorage.setItem('locale', key)
      this.$i18n.locale = localStorage.getItem('locale')
    },
  },
}
</script>
<style lang="less" scoped>
@import url('./style.css');
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2147483006;
  background-color: #fff;

  .network {
    background: #3b3b3b;
    min-height: 33px;
    line-height: 33px;
    color: #fff;
    text-align: center;
    font-size: 14px;

    .unprotected {
      color: @unprotected-color;
    }

    .network-icon {
      width: 20px;
      height: 17px;
      vertical-align: sub;
    }

    ::v-deep {
      .ivu-tooltip,
      .ivu-tooltip-rel {
        display: inline;
        height: 16px;
      }
    }

    ::v-deep .ivu-tooltip-popper[x-placement^='bottom'] .ivu-tooltip-arrow {
      border-bottom-color: #eee;
    }

    ::v-deep .ivu-tooltip-inner {
      color: #999;
      background: #eee;
    }
  }
}

.header-content {
  height: 3.75rem;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 0 0 #e0e0e0;

  .logo {
    display: inline-block;
    width: 12.375rem;
    height: 2.5rem;
    margin-left: 15px;
  }

  .header-menu {
    flex: 1;
    text-align: right;
    display: flex;
    justify-content: flex-end;

    .header-dropdown-link,
    a {
      color: #7f7f7f;
      font-size: 1.125rem;
      display: inline-block;
      padding: 0 15px;
      cursor: pointer;
      font-weight: 700;

      &.active {
        color: #262626;
      }
    }
  }
}

header .header-content .header-menu .header-dropdown-link,
header .header-content .header-menu a {
  color: #7f7f7f;
  font-size: 1.125rem;
  display: inline-block;
  padding: 0 15px;
  cursor: pointer;
  font-weight: 700;
}

.menu-bottom {
  margin-left: 5px;
  margin-top: -3px;
  width: 18px;
  height: 18px;
  border: none;
  vertical-align: middle;
  max-width: 100%;
}

@media screen and (max-width: 1279px) {
  header .header-content .header-menu {
    display: none;
  }
}
</style>
