<template>
  <div id="app">
    <!-- <Layout>
      <Header :style="{position: 'fixed', width: '100%', padding: 0}">
      </Header>
    </Layout> -->
    <layout-header />
    <div id="appMain">
      <div class="page-box">
        <router-view />
      </div>
      <LayoutFooter />
    </div>
    <CookieConsent />
    <BannerPopup />
  </div>
</template>

<script>
import LayoutHeader from '@/components/LayoutHeader'
import LayoutFooter from '@/components/LayoutFooter'
import CookieConsent from '@/components/CookieConsent'
import BannerPopup from '@/components/BannerPopup'
export default {
  name: 'App',
  components: {
    LayoutHeader,
    LayoutFooter,
    CookieConsent,
    BannerPopup,
  },
  watch: {
    '$route.name': function () {
      this.$nextTick(() => {
        window.scroll(0, 0)
      })
    },
  },
}
</script>

<style>
@import url(@/assets/styles/common.css);
#appMain {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.page-box {
  padding-top: 93px;
  flex-grow: 1;
}
.index-top {
  z-index: 2147483006 !important; 
}
</style>
